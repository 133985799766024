<template>
  <div class="grjws">
    <div class="title">赡养人记录</div>
    <div>
      <el-button type="primary" icon="el-icon-circle-plus" @click="add">新增</el-button>
      <div class="tableBox">
        <hltableZ :columnEdit="table.columnEdit" :tableColumn="table.column" :refreshFlag="table.refreshFlag"
          :selectedRows="table.selectedRows" :isChecked="table.isChecked" :isNumber="table.isNumber" :formSearch="query"
          :supporterEntityList="dataProps.result.supporterEntityList" :apiurl="apiurl" :isPage="false">
          <!-- <div slot-scope="selected" slot="EditColumn">
            <el-button
              icon="el-icon-delete"
              type="text"
              size="small"
              @click="delete selected.singlerow"
              >删除</el-button
            >
          </div> -->
        </hltableZ>
      </div>
    </div>
    <Drawer :title="title" ref="drawerIshow" :drawerSize="drawerSize">
      <div class="qtxx">
        <div class="content">
          <el-form :label-position="labelPosition" label-width="170px" :model="formLabelAlign" :inline="true"
            :rules="rules" ref="formLabelAlign" :hide-required-asterisk="true" style="display: flex; flex-wrap: wrap">
            <el-form-item label="姓名:" prop="userName" style="width: 100%">
              <el-input v-model="formLabelAlign.userName"></el-input>
            </el-form-item>
            <el-form-item label="电话:" prop="phone" style="width: 100%">
              <el-input v-model="formLabelAlign.phone"></el-input>
            </el-form-item>
            <el-form-item label="与老人关系:" style="width: 100%">
              <el-select v-model="formLabelAlign.relation" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="工作是否稳定:" prop="name" style="width: 100%">
              <el-input v-model="formLabelAlign.name"></el-input>
            </el-form-item>
            <el-form-item label="备注:" prop="remark" style="width: 100%">
              <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="formLabelAlign.remark"
                style="width: 30rem">
              </el-input>
            </el-form-item>
            <el-form-item style="width: 100%; text-align: center">
              <el-button type="primary" @click="onSubmit">保存</el-button>
              <el-button @click="close">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
import { hltableZ, Drawer } from "@/components";

export default {
  name: "YanglaoSyr",
  components: { hltableZ, Drawer },
  props: {
    dataProps: {
      type: Object,
      default: ''
    }
  },
  data() {
    return {
      drawerSize: "50%",
      drawerIshow: false,
      title: "新增",
      query: {
        userId: this.dataProps.result.id
      },
      apiurl: 'homePage/getDataByUserId',
      table: {
        columnEdit: false,
        column: [
          {
            label: "姓名",
            prop: "userName",
            checked: true,
          },
          {
            label: "电话",
            prop: "phone",
            checked: true,
          },
          {
            label: "与老人关系",
            prop: "relation",
            checked: true,
          },
          {
            label: "工作是否稳定",
            prop: "workCondition",
            checked: true,
          },
          {
            label: "工作情况",
            prop: "workCondition",
            checked: true,
          },
          {
            label: "备注",
            prop: "remark",
            checked: true,
          },
        ],
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
      labelPosition: "right",
      formLabelAlign: {
        relation: "",
        userName: "",
        workCondition: "",
        remark: "",
        relation: "",
        phone: '',
        userId: this.dataProps.result.id
      },
      rules: {
        name: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
        ],
        region: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
        type: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动性质",
            trigger: "change",
          },
        ],
        resource: [
          { required: true, message: "请选择活动资源", trigger: "change" },
        ],
        idCard: [
          { required: true, message: "请填写活动形式", trigger: "blur" },
        ],
      },
      checkList: [],
    };
  },

  mounted() {
    this.dataRefresh()
  },

  methods: {
    // 新增
    add() {
      this.$refs.drawerIshow.drawer = true;
      const th = this;
      Object.keys(th.formLabelAlign).forEach(function (key) {

        if (key == "userId") {
          th.formLabelAlign.userId = this.dataProps.result.id
        } else {
          th.formLabelAlign[key] = "";
        }

      });
    },
    close() {
      this.$refs.drawerIshow.drawer = false;
    },
    onSubmit() {

      this.$store.dispatch("homePage/supporterSave", this.formLabelAlign).then((res) => {
        this.$refs.drawerIshow.drawer = false;
        this.dataRefresh()
      })
    },
    dataRefresh() {
      this.table.refreshFlag = new Date().getTime().toString();
    },
  },
};
</script>

<style lang="scss" scoped>
.grjws {
  width: 100%;

  button {
    margin: 1rem;
  }

  .tableBox {
    height: 30rem;
    width: 100%;
  }
}

.el-input {
  width: 30rem;
}

.el-select {
  width: 30rem;
}

// 所有的蓝色标签
.title {
  width: 98%;
  border-bottom: 1px solid #999;
  padding-bottom: 0.5rem;
  margin: 1rem auto;
  font-size: 1rem;
  color: rgb(50, 50, 255);
}
</style>